import React from "react";
import styled from "styled-components";
import About from "../components/Home/About";
import Contact from "../components/Home/Contact";
import Hero from "../components/Home/Hero";

const StyledHome = styled.div`

`;

const Home = (props) => {
    return (
        <StyledHome>
            <Hero id="Hero" />
            <About id="about" />
            <Contact id="contact" />
        </StyledHome>
    );
};

export default Home;
