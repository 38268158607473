import React from "react";
import styled from "styled-components";
import {
    twitter as hrefTwitter,
    twitch as hrefTwitch,
    instagram as hrefInstagram,
    youtube as hrefYoutube,
    tiktok as hrefTiktok,
    // snapchat as hrefSnapchat,
    // paypal as hrefPaypal,
} from "../../assets/social-info";
import device from "../../util/device";

const StyledSocials = styled.div`
    margin-top: 60px;
    text-align: center;
    p {
        font-size: 1.4rem;
    }
    i {
        font-size: 3rem;
        padding: 10px 20px;
        transition: color 0.1s ease-in-out;
        color: ${(props) => props.theme.text.dark};
    }
    .social-links1 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        width: 350px;
        margin: 0 auto;
        .fa-instagram:hover {
            cursor: pointer;
            color: #fff;
        }
        .fa-twitter:hover {
            cursor: pointer;
            color: #00acee;
        }
        .fa-youtube:hover {
            cursor: pointer;
            color: #ff0000;
        }
        .fa-tiktok:hover {
            cursor: pointer;
            color: #69c9d0;
        }
        .fa-twitch:hover {
            cursor: pointer;
            color: #6441a5;
        }
        @media ${device.phone} {
            margin-top: 10px;
        }
    }
    .social-links2 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: center;
        margin: 10px 0 20px 0;
        .snapchat {
            grid-column: 2/3;
            &:hover {
                .fa-snapchat-ghost {
                    cursor: pointer;
                    color: #fffc00;
                }
                p {
                    color: #fffc00;
                }
            }
        }
        .paypal {
            grid-column: 3/4;
            &:hover {
                .fa-paypal {
                    cursor: pointer;
                    color: #00457c;
                }
                p {
                    color: #00457c;
                }
            }
        }
        p {
            margin-top: -5px;
        }
    }
`;

const socialList = [
    {
        href: { hrefInstagram },
        className: "fab fa-instagram",
    },
    {
        href: { hrefTwitter },
        className: "fab fa-twitter",
    },
    {
        href: { hrefYoutube },
        className: "fab fa-youtube",
    },
    {
        href: { hrefTiktok },
        className: "fab fa-tiktok",
    },
    {
        href: { hrefTwitch },
        className: "fab fa-twitch",
    },
];

const Socials = () => {
    return (
        <StyledSocials>
            <p>my socials</p>
            <div className="social-links1">
                {socialList.map(({ href, className }, idx) => (
                    <a href={href} target="_blank" rel="noopener noreferrer">
                        <i className={className} />
                    </a>
                ))}
            </div>
            {/* <div className="social-links2">
                <div className="snapchat">
                    <i className="fab fa-snapchat-ghost"></i>
                </div>
                <div className="paypal">
                    <i className="fab fa-paypal"></i>
                </div>
            </div> */}
        </StyledSocials>
    );
};

export default Socials;
