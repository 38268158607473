module.exports = {
    name: "Tifamela",
    location: "LA",
    email: "tifamela4@gmail.com",
    twitter: "https://twitter.com/tifamela",
    twitch: "https://twitch.tv/tifamela",
    instagram: "https://www.instagram.com/tifamela4",
    youtube: "https://www.youtube.com/user/Amaya339",
    snapchat: "",
    tiktok: "https://www.tiktok.com/@tifamela",
    paypal: "",
};
